
import { defineComponent, ref } from "vue";
import { apiPost } from "@/api";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { Toast } from "@/utils/notify";
import { useRoute } from "vue-router";
import { ShowSuccess } from "@/utils/notify";
import InputWrapper from "@/components/InputWrapper.vue";

export default defineComponent({
  name: "CreateCardEntryForm",
  components: { InputWrapper, DualRingLoader },
  emits: ["save"],
  setup() {
    const formData = ref({
      description: null,
      rate: null,
      range: null,
      tac: null,
    });
    const formError = ref({});
    const route = useRoute();
    const loading = ref(false);
    async function Submit() {
      loading.value = true;
      try {
        const response = await apiPost(
          `/admin/cards/${route.params.giftCardId}`,
          formData.value
        );
        ShowSuccess("Entry Created Successfully", () => {
          formData.value = {
            description: null,
            rate: null,
            range: null,
            tac: null,
          };
        });
      } catch (e:any) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to create entry: " + e.message,
        }).then();
      }
      loading.value = false;
    }
    return {
      Submit,
      formData,
      formError,
      loading,
    };
  },
});
